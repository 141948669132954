import { CCard, CCardBody, CCol, CContainer, CForm, CRow } from '@coreui/react'
import React, { useState } from 'react'
import Form from '../../../components/utils/generic/Form'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkCodemail, modifyPassword, sendEmail } from '../../../components/utils/function/Api'
import ShowAlert from '../../../components/utils/AlertToast'
import Loader from '../../../components/utils/composant/Loader'
import swal from 'sweetalert'
/* eslint-disable no-unused-vars */
export default function Recovery() {
  const navigate = useNavigate()
  const location = useLocation()
  const [loader, setLoader] = useState(false)
  // eslint-disable-next-line react/prop-types
  const [formValues, setFormValues] = useState({
    code: location.state.code,
    email: location.state.email,
  })
  const [error, setError] = useState()
  const properties = [
    {
      type: 'text',
      name: 'code',
      label: 'un code de vérification a été envoyer à votre email, inscrire le code ',
      selector: (data) => data.code,
      change: (e) => setFormValues({ ...formValues, code: e.target.value }),
    },
  ]
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoader(true)
    checkCodemail(formValues.email, formValues.code)
      .then(async () => {
        await setLoader(false)
        navigate('/reset', { state: { email: formValues.email } })
      })
      .catch((error) => {
        setLoader(false)
        setError(error.message)
      })
  }
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <FormContainer>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      {error && <ShowAlert visible={true} message={error} />}
      {loader && <Loader onClose={() => setLoader(false)} visible={loader} />}
      <Form
        data={formValues}
        properties={properties}
        labelButton={'valider'}
        submit={handleSubmit}
      />
    </FormContainer>
  )
}
export function FormEmail() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [formValues, setFormValues] = useState({
    // eslint-disable-next-line react/prop-types
    code: '',
    // eslint-disable-next-line react/prop-types
    email: '',
  })
  const [error, setError] = useState()
  const properties = [
    {
      type: 'email',
      name: 'email',
      label: 'entrer votre addresse mail',
      selector: (data) => data.email,
      change: (e) => setFormValues({ ...formValues, email: e.target.value }),
    },
  ]
  const handleSubmit = (event) => {
    event.preventDefault()
    // navigate('/confirm_code', { state: { code: formValues.code, email: formValues.email } })
    setLoader(true)
    // navigate('/confirm_code', { state: { code: formValues.code, email: formValues.email } })
    sendEmail(formValues.email)
      .then(async () => {
        await setLoader(false)
        navigate('/confirm_code', { state: { code: formValues.code, email: formValues.email } })
      })
      .catch((error) => {
        setLoader(false)
        setError(error.message)
      })
  }
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <FormContainer>
      {error && <ShowAlert visible={true} message={error} onCloseAlert={setError} />}
      {loader && <Loader onClose={() => setLoader(false)} visible={loader} />}
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <Form
        data={formValues}
        properties={properties}
        labelButton={'valider'}
        submit={handleSubmit}
      />
    </FormContainer>
  )
}
export function ResetPassword() {
  const location = useLocation()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    mail: location.state.email,
    naissance: '',
    societe: '',
    password: '',
    pwd: '',
    role: '0',
  })
  const [error, setError] = useState()
  const properties = [
    {
      type: 'password',
      name: 'password',
      label: 'entrer votre mot de passe',
      selector: (data) => data.password,
      change: (e) => setFormValues({ ...formValues, password: e.target.value }),
    },
    {
      type: 'password',
      name: 'pwd',
      label: 'retaper votre mot de passe',
      selector: (data) => data.pwd,
      change: (e) => setFormValues({ ...formValues, pwd: e.target.value }),
    },
  ]
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoader(true)
    modifyPassword(formValues)
      .then(async () => {
        await setLoader(false)
        swal('mot de passe modifier!', {
          icon: 'success',
        }).then(() => {
          navigate('/login')
        })
      })
      .catch((error) => {
        setLoader(false)
        setError(error.message)
      })
  }
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <FormContainer>
      <h1>Modification de mot de passe</h1>
      {error && <ShowAlert visible={true} message={error} />}
      {loader && <Loader onClose={() => setLoader(false)} visible={loader} />}
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <Form
        data={formValues}
        properties={properties}
        labelButton={'valider'}
        submit={handleSubmit}
      />
    </FormContainer>
  )
}
export const FormContainer = (props) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <CContainer>
        {/* eslint-disable-next-line react/react-in-jsx-scope */}
        <CRow className="justify-content-center">
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <CCol md={9} lg={7} xl={6}>
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <CCard className="mx-4">
              {/* eslint-disable-next-line react/react-in-jsx-scope */}
              <CCardBody className="p-4">
                {/* eslint-disable-next-line react/react-in-jsx-scope,react/prop-types */}
                <CForm>{props.children}</CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
