import React, { useState } from 'react'
import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'
/* eslint-disable no-unused-vars */
const EditableGallery = ({ links, changeLinks }) => {
  const [images, setImages] = useState(links)
  const deleteImage = (link) => {
    const filtered = [...images.filter((l) => l !== link)]
    changeLinks(filtered)
    setImages(filtered)
  }
  return (
    <ImageList>
      {images.map((link, index) => (
        <ImageListItem key={link}>
          <img src={`${link}?=w=164&h=164&fit=crop&auto=format`} alt={index} loading="lazy" />
          <ImageListItemBar
            actionIcon={
              <IconButton onClick={() => deleteImage(link)}>
                <CIcon content={cilTrash} width="24" height="24" color="red" />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}
EditableGallery.propTypes = {
  links: PropTypes.array,
  changeLinks: PropTypes.func,
}
export default EditableGallery
