import React from 'react'
import PropTypes from 'prop-types'
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import { FormControl, TextField } from '@mui/material'
import EditableGallery from './EditableGallery'
import SearchableDatatable from './SearchableDatatable'
import CLoadButton from '../composant/CloadButton'
/* eslint-disable no-unused-vars */
const Form = ({
  data,
  properties,
  submit,
  multiple,
  multipleData,
  add,
  multipleSelectionHandler,
  contextActions,
  labelButton,
  onCancel,
}) => {
  const basicInputs = [
    'text',
    'number',
    'email',
    'password',
    'date',
    'time',
    'datetime-local',
    'month',
    'week',
  ]
  let columns = []
  if (multiple) {
    columns = properties.map((property) => {
      return {
        name: property.label,
        selector: property.selector,
        sortable: true,
      }
    })
  }
  return (
    <>
      {properties.map((propety) => (
        <div className={'row mb-2'} key={propety.id}>
          <div className={'col-12'}>
            {basicInputs.find((input) => {
              return input === propety.type
            }) !== undefined && (
              <CFormInput
                variant={'standard'}
                type={propety.type}
                defaultValue={propety.selector(data)}
                name={propety.name}
                label={propety.label}
                fullWidth
                onChange={(e) => propety.change(e)}
              />
            )}
            {propety.type === 'textArea' && (
              <TextField
                variant={'standard'}
                label={propety.label}
                defaultValue={propety.selector(data)}
                multiline
                style={{ color: 'black', textDecoration: 'none' }}
                maxRows={4}
                fullWidth
                onChange={propety.change}
              />
            )}
            {propety.type === 'select' && (
              <FormControl variant={'standard'} fullWidth>
                <CFormSelect
                  labelId={propety.label}
                  name={propety.name}
                  defaultValue={propety.selector(data)}
                  variant={'standard'}
                  onChange={propety.change}
                >
                  {propety.options.map((option) => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </CFormSelect>
              </FormControl>
            )}
            {propety.type === 'disabled' && (
              <>
                <CFormLabel>{propety.label}</CFormLabel>
                <p>{propety.selector(data)}</p>
              </>
            )}
            {propety.type === 'file' && (
              <>
                <CFormInput
                  type="file"
                  size="sm"
                  name={propety.name}
                  id="formFileSm"
                  defaultValue={propety.selector(data)}
                  onChange={propety.change}
                  label="inserer un fichier"
                />
                <>{propety.selector(data)}</>
              </>
            )}
            {propety.type === 'image-gallery' && (
              <>
                <CFormLabel>{propety.label}</CFormLabel>
                <EditableGallery links={propety.selector(data)} changeLinks={propety.change} />
              </>
            )}
          </div>
        </div>
      ))}
      <CRow>
        <CCol sm={10}>
          <CLoadButton
            color="primary"
            // className="px-4"
            submit={multiple ? add : submit}
            label={multiple ? 'Ajouter' : !labelButton ? 'enregistrer' : labelButton}
          />
        </CCol>
        {onCancel && (
          <CCol sm={1}>
            <CButton color="secondary" onClick={onCancel}>
              annuler
            </CButton>
          </CCol>
        )}
      </CRow>
      {multiple && (
        <>
          <SearchableDatatable
            title={'Catégories à enregistrer'}
            data={multipleData}
            columns={columns}
            selectable
            handleRowSelection={multipleSelectionHandler}
            contextActions={contextActions}
          />
          <div className="d-grid">
            {/*<CButton color={'primary'} onClick={submit}>*/}
            {/*  {!labelButton ? 'enregistrer' : labelButton}*/}
            {/*</CButton>*/}
            <CLoadButton
              color="primary"
              // className="px-4"
              submit={submit}
              label={!labelButton ? 'enregistrer' : labelButton}
            />
            {onCancel && (
              <CButton color="secondary" onClick={onCancel}>
                annuler
              </CButton>
            )}
          </div>
        </>
      )}
    </>
  )
}
Form.propTypes = {
  data: PropTypes.object,
  properties: PropTypes.array,
  submit: PropTypes.func,
  add: PropTypes.func,
  delete: PropTypes.func,
  onCancel: PropTypes.func,
  multiple: PropTypes.bool,
  multipleData: PropTypes.array,
  multipleSelectionHandler: PropTypes.func,
  contextActions: PropTypes.object,
  labelButton: PropTypes.string,
}

export default Form
