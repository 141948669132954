import React, { useState } from 'react'
import { CButton, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
/* eslint-disable no-unused-vars */
const CLoadButton = ({ submit, label, color }) => {
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async (event) => {
    setLoading(true)
    await submit(event)
    // Simulate a delay
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    // setLoading(false)
  }

  return (
    <CButton color={color} onClick={handleButtonClick} disabled={loading}>
      {loading ? (
        <>
          <CSpinner color="dark" size={'sm'} className="mr-2" />
          Loading...
        </>
      ) : (
        label
      )}
    </CButton>
  )
}
CLoadButton.propTypes = {
  submit: PropTypes.func,
  label: PropTypes.string,
  color: PropTypes.string,
}
export default CLoadButton
