import React from 'react'
import { CModal, CModalBody, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
/* eslint-disable no-unused-vars */
const Loader = ({ visible, onClose }) => {
  return (
    <CModal
      alignment="center"
      size={'sm'}
      visible={visible}
      onClose={onClose}
      backdrop="static"
      className="border-0 bg-transparent"
      style={{
        marginBottom: '20%',
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: 'transparent',
        border: 'none',
      }}
    >
      <CModalBody
        className="p-3 d-flex justify-content-center align-items-center bg-transparent border-0"
        style={{ width: 'fit-content', height: 'fit-content' }}
      >
        <strong role="status">chargement...</strong>
        <CSpinner className="ms-auto" />
      </CModalBody>
    </CModal>
  )
}

export default Loader
Loader.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}
