import axios from 'axios'
import { TokenUser } from './Utils'

export let maxSizePage = 50
export function setMaxSize(number) {
  maxSizePage = number
}
export const base_directory = 'src\\main\\resources\\directory\\'
export function splitDirectory(idperson, path) {
  const root = base_directory + idperson
  let extractedPath = ''
  try {
    extractedPath = path.substring(path.indexOf(root) + root.length)
  } catch (error) {
    // Ignored
  }
  const separator = /[\/\\]/
  return extractedPath.split(separator)
}
//const host = 'http://102.16.82.3:8000'

const host = 'https://api.ticket.sunsoft.cloud'
// const host = 'http://localhost:8000'
// const host = 'http://192.168.88.36:8000'
export const getHost = host
export const getCall = (url, auth = false, customHeaders = {}) => {
  let config = {}
  if (auth) {
    config = { headers: { Authorization: `Bearer ${localStorage.getItem(TokenUser)}` } }
  }
  config.headers = { ...config.headers, ...customHeaders }
  return axios
    .get(url, config)
    .then((res) =>
      res.status === 200 || res.status === 201 || res.status === 202 ? res : Promise.reject(res),
    )
    .then((res) => res.data.data)
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
          // Handle 403 error here
          console.log(error)
          throw new axios.Cancel('Accès refuser, veuillez vous reconnecter')
        } else if (error.response && error.response.data && error.response.data.error) {
          // Create a custom Axios error with a modified error message
          throw new axios.Cancel(error.response.data.error)
        } else if (error.message === 'Network Error') {
          throw new axios.Cancel('Veuillez vérifier votre connexion internet.')
        }
      }
      throw error
    })
}

export const postCall = (url, data, auth = false, headers = false, customHeaders = {}) => {
  let config = {}
  if (auth) {
    config = { headers: { Authorization: `Bearer ${localStorage.getItem(TokenUser)}` } }
    if (headers) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
  }
  config.headers = { ...config.headers, ...customHeaders }

  return axios
    .post(url, data, config)
    .then((res) =>
      res.status === 200 || res.status === 201 || res.status === 202 ? res : Promise.reject(res),
    )
    .then((res) => res.data.data)
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
          // Handle 403 error here
          throw new axios.Cancel('Accès refuser, veuillez vous reconnecter')
        } else if (error.response && error.response.data && error.response.data.error) {
          // Create a custom Axios error with a modified error message
          throw new axios.Cancel(error.response.data.error)
        } else if (error.message === 'Network Error') {
          throw new axios.Cancel('Veuillez vérifier votre connexion internet.')
        }
      }
      throw error
    })
}

export const putCall = (url, data, auth = false, headers = false, customHeaders = {}) => {
  let config = {}
  if (auth) {
    config = { headers: { Authorization: `Bearer ${localStorage.getItem(TokenUser)}` } }
    if (headers) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
  }
  config.headers = { ...config.headers, ...customHeaders }
  return axios
    .put(url, data, config)
    .then((res) => (res.status === 200 ? res : Promise.reject(res)))
    .then((res) => res.data.data)
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
          // Handle 403 error here
          throw new axios.Cancel('Accès refuser, veuillez vous reconnecter')
        } else if (error.response && error.response.data && error.response.data.error) {
          console.error(error)
          // Create a custom Axios error with a modified error message
          throw new axios.Cancel(error.response.data.error)
        } else if (error.message === 'Network Error') {
          throw new axios.Cancel('Veuillez vérifier votre connexion internet.')
        }
      }
      throw error
    })
}
export const deleteCall = (url, auth = false, customHeaders = {}) => {
  let config = {}
  if (auth) {
    config = { headers: { Authorization: `Bearer ${localStorage.getItem(TokenUser)}` } }
  }
  config.headers = { ...config.headers, ...customHeaders }
  return axios
    .delete(url, config)
    .then((res) => (res.status === 200 ? res : Promise.reject(res)))
    .then((res) => res.data.data)
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
          // Handle 403 error here
          throw new axios.Cancel('Accès refuser, veuillez vous reconnecter')
        } else if (error.response && error.response.data && error.response.data.error) {
          // Create a custom Axios error with a modified error message
          throw new axios.Cancel(error.response.data.error)
        } else if (error.message === 'Network Error') {
          throw new axios.Cancel('Veuillez vérifier votre connexion internet.')
        }
      }
      throw error
    })
}
export const exporterExcel = async (formFilter) => {
  try {
    const token = localStorage.getItem(TokenUser) // Récupérez le token depuis le local storage
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Ajoutez l'en-tête d'autorisation
        'Content-Type': 'application/json', // Assurez-vous que le type de contenu correspond à votre backend
      },
      responseType: 'blob', // Indique que la réponse est un fichier binaire (Excel)
    }
    const response = await axios.post(host + '/crud/excel/form', formFilter, config)

    if (response.status === 200) {
      // Créez un lien pour télécharger le fichier Excel
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Liste_clients_filtrer.xlsx')
      document.body.appendChild(link)
      link.click()
    } else {
      console.error("Erreur lors de l'export.")
    }
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
  }
}
// url pour personne
const login_url = `${host}/person/login`
const saveAnyDeskURI = `${host}/team/save`
const saveOtherAnyDeskURI = `${host}/other/save`
const updateOtherAnyDeskURI = `${host}/other/updateOther`
const getOtherAnyDeskURI = `${host}/other/all_auth`
const register_url = `${host}/person/register`
const getuserURI = `${host}/crud/all`
const FusionURI = `${host}/crud/fusion`
const getUserURIbyRole = `${host}/crud/all/role`
const modifypersonURI = `${host}/crud/update`
const exportExcel = `${host}/crud/excel`
const FilterPersonURI = `${host}/crud/filter/`
const updateInfoPersonURI = `${host}/crud/updateperson`
export const deletepersonURI = `${host}/crud/delete`
const TokenURI = `${host}/person/checkToken`
// url pour service
const AllService = `${host}/service/all`
export const deleteServiceURI = `${host}/service/delete`
const updateServiceURI = `${host}/service/update`
const createServiceURI = `${host}/service/save`
const createCategorieURI = `${host}/catego/save`
const updateCategorieURI = `${host}/catego/update`
const AllCategoByServiceURI = `${host}/catego/all`
const AllCategoByIDServiceURI = `${host}/catego/all/categories/`
export const deleteCategoURI = `${host}/catego/delete`

//url pour reset password
const sendCodeURI = `${host}/person/reset_password`
const checkCode_mailURI = `${host}/person/check_code`
const modifyPasswordURI = `${host}/person/validate_reset/password`

// url pour tout ce qui est crud ticket
const createTicketURI = `${host}/ticket/save/ticket`
const createTicketURIway = `${host}/ticket/save/way`
const getAllTicketURI = `${host}/ticket/all`
const udpateCheckedTicketURI = `${host}/ticket/update/checked`
const filterDateTicketURI = `${host}/ticket/filterdate`
export const deleteTicketURI = `${host}/ticket/delete`
const modifyTicketURI = `${host}/ticket/update/ticket`
const modifyTicketURIway = `${host}/ticket/update/way`
export const assignationURL = `${host}/ticket/resp/save`
export const modifierStatusTicket = `${host}/ticket/update/status`
export const modifierStatus = `${host}/sousTache/update/status`
export const modifierFacturation = `${host}/ticket/setFacturation/`
export const modifierDescriAgent = `${host}/ticket/descriAgent/`
const modifierDateURI = `${host}/ticket/update/date`

// url pour recuperer les messages des tickets

const getChatTicketURI = `${host}/chat/`
const saveChatTicketURI = `${host}/save`

// Rating ticket
const RateTicketURI = `${host}/ticket/rate`
const GetrateTicketURI = `${host}/ticket/find/rate`
// url profile file
const addFileURL = `${host}/file/upload`
const importFileURL = `${host}/person/importcsv`
const addFolderURL = `${host}/file/create/dossier`
const getFolderURL = `${host}/file/all`
const person_fileURI = `${host}/file/person_file`
export const deleteFileUserURI = `${host}/file/delete/file`
const saveProfileURI = `${host}/profile/save`
const getProfileURI = `${host}/profile/findfile`

// url file ticket
const addFileTicketURI = `${host}/file_ticket/save`
const getFileTicketURI = `${host}/file_ticket/maka`
// const getFileTicketURI = `${host}/file_ticket/all`
export const deleteFileTicketURI = `${host}/file_ticket/delete`

// url ilaina am zanaka ticket
const getSousTicketURI = `${host}/sousTache/all/soustache`
const getsubTask = `${host}/sousTache/all/soustache/zanaka`
const saveSousTicketURI = `${host}/sousTache/save`
export const deleteSousTicketURI = `${host}/sousTache/delete`

// calendar
const getCalendarByDate = `${host}/ticket/calendar`
const getCalendarByDateLate = `${host}/ticket/calendar/lates`
//fliter ticket
const filterTicketURI = `${host}/ticket/filter`
const count = `${host}/ticket/count/all`
const statTotalfinish = `${host}/ticket/stat/finish/`
const chartTicketURI = `${host}/ticket/chart/`
const statistiqueByService = `${host}/ticket/service/stat`
const statistiqueByCatego = `${host}/ticket/service/stat/catego`
const statTotalfinishByUser = `${host}/ticket/stat/finish/user/`
const statStatusBymonth = `${host}/ticket/stat/number/`
const statPerformanceBymonth = `${host}/ticket/stat/performance/`

// abonnement
const allAbonnementURI = `${host}/abonnement/all`
const setAbonnementURI = `${host}/abonnement/setabonne`
const getAbonnementVitaURI = `${host}/abonnement/getCurrentAbonnement/`
//search bar function
const searchBarcliURI = `${host}/crud/filtercli`
export const searchBar = (text) => {
  const uri = `${searchBarcliURI}?text=${text}`
  return getCall(uri, true)
}
export const downloadExcel = () => {
  return getCall(exportExcel, true)
}
export const getAllAbonnement = () => {
  return getCall(allAbonnementURI, true)
}
export const getAllAbonnementCLI = (id) => {
  return getCall(getAbonnementVitaURI + id, true)
}
export const setAbonnementCLI = (list) => {
  return postCall(setAbonnementURI, list, true)
}
// message ticket function

export const getAllChatTicket = (idticket, row = 0, maxSize = 10) => {
  return getCall(getChatTicketURI + idticket + '?row=' + row + '&max=' + maxSize, true)
}
export const sendMessage = (form) => {
  return postCall(saveChatTicketURI, form, true)
}
// facture
const hostFacture = `${host}/facture`
const saveFactureURI = `${host}/facture/save/one`
//forum
const saveAllProblemURI = `${host}/forum/save`
const updateAllProblemURI = `${host}/forum/update/problem`
const solutionsaveURI = `${host}/forum/save/solution`
const getAllProblemURI = `${host}/forum/all`
const searchAllProblemURI = `${host}/forum/search`
export const deleteProblemURI = `${host}/forum/deleteone`
export const deletesolutionURI = `${host}/forum/solution/deleteone`

//fonctioon ilaina any anaty forum
export const saveProblem = (problem) => {
  return postCall(saveAllProblemURI, problem, true)
}
export const updateProblem = (problem) => {
  return postCall(updateAllProblemURI, problem, true)
}
export const saveSolution = (solution) => {
  return postCall(solutionsaveURI, solution, true)
}
export const getProblem = () => {
  return getCall(getAllProblemURI, true)
}
export const searchProblem = (body) => {
  return postCall(searchAllProblemURI, body, true)
}

// function ilaina am service
export const getAllService = () => {
  return getCall(AllService, true)
}
export const getAllServicePaginate = (row = 0, maxSize = maxSizePage) => {
  return getCall(AllService + '/' + row + '/' + maxSize, true)
}

export function modifyService(service) {
  return putCall(updateServiceURI, service, true)
}
export const saveService = (service) => {
  return postCall(createServiceURI, service, true)
}
export const saveCatego = (form) => {
  return postCall(createCategorieURI, form, true)
}
export const updateCatego = (form) => {
  return putCall(updateCategorieURI, form, true)
}
export const findAllCatego = (page, idservice, size = maxSizePage) => {
  const uri = AllCategoByServiceURI + `/${page}/${size}/${idservice}`
  return getCall(uri, true)
}
export const findAllCategoById = (idservice) => {
  const uri = AllCategoByIDServiceURI + `${idservice}`
  return getCall(uri, true)
}

// function ilaina am gestion personne
export const login = (user) => {
  return postCall(login_url, user, false, false, { action: 'log' })
}
export const saveAnyDesk = (anydesk) => {
  return postCall(saveAnyDeskURI, anydesk, true)
}
export const saveOtherAnyDesk = (anydesk) => {
  return postCall(saveOtherAnyDeskURI, anydesk, true)
}
export const updateOtherAnyDesk = (anydesk) => {
  return postCall(updateOtherAnyDeskURI, anydesk, true)
}
export const getAllOtherdesk = (idperson) => {
  return getCall(getOtherAnyDeskURI + '?idperson=' + idperson, true)
}
export const logout = (id) => {
  localStorage.removeItem(TokenUser)
  // return getCall(logout_url + '/' + id, true, { action: 'log' })
}
export const register = (user, iddesk, password, passwordserver) => {
  const uri = `${register_url}?iddesk=${iddesk}&password=${password}&passwordserver=${passwordserver}`
  return postCall(uri, user, true)
}
export const fusionPerson = (fusion) => {
  return postCall(FusionURI, fusion, true)
}
export function getAllPerson(role = -1, row = 0, maxSizepage = maxSizePage) {
  return getCall(
    role === -1 || role === -2
      ? getuserURI + '/' + row + '/' + maxSizepage
      : getUserURIbyRole + '/' + role,
    true,
  )
}
export function modifyPerson(user, iddesk = null, password = null, passwordserver = null) {
  const uri = `${modifypersonURI}/person?iddesk=${iddesk}&password=${password}&passwordserver=${passwordserver}`
  return putCall(uri, user, true)
}
export function getPersonById(id) {
  const uri = getuserURI + '/' + id
  return getCall(uri, true)
}

//function ilaina am reinitialisation mot de passe
export function sendEmail(email) {
  return getCall(sendCodeURI, false, { email: email, action: 'log' })
}
export function checkCodemail(email, code) {
  return getCall(checkCode_mailURI, false, { email: email, code: code, action: 'log' })
}
export function modifyPassword(data) {
  return putCall(modifyPasswordURI, data, false)
}
export function checkTokenUser(id, token) {
  return getCall(TokenURI, false, { token: token, id: id })
}

// function crud ticket
export function getFilterDate(debut, fin, status = -1, id) {
  let uri = `${filterDateTicketURI}?debut=${debut}&fin=${fin}&idperson=${id}`
  if (status === 2) uri += '&status=' + status
  return getCall(uri, true)
}
export function updateChecked(form) {
  return postCall(udpateCheckedTicketURI, form, true)
}
export function getAllTicket(row = 0, status = -1, idperson, isclient, maxsize = maxSizePage) {
  let path = getAllTicketURI + '/' + row + '/' + maxsize
  if (status !== -1) path += '/' + status
  if (isclient != null) path += '?idresp=' + idperson + '&action=' + isclient

  return getCall(path, true)
}
export function createTicket(ticket) {
  // console.log(ticket)
  return postCall(createTicketURI, ticket, true, true)
}
export function createTicketway(ticket) {
  // console.log(ticket)
  return postCall(createTicketURIway, ticket, true, true)
}
export function putTicket(ticket) {
  return putCall(modifyTicketURI, ticket, true, true)
}
export function putTicketway(ticket) {
  return putCall(modifyTicketURIway, ticket, true, true)
}
export function putDateTicket(ticket) {
  // console.log(ticket)
  return putCall(modifierDateURI, ticket, true)
}
export function RateTicket(idticket, value) {
  const uri = `${RateTicketURI}/${idticket}?value=${value}`
  return postCall(uri, null, true)
}
export function GetRateTicket(idticket) {
  const uri = `${GetrateTicketURI}/${idticket}`
  return getCall(uri, true)
}

export function putFacturation(idticket, facturable) {
  const uri = modifierFacturation + `${idticket}/${facturable}`
  return putCall(uri, null, true)
}
export function putmodifierDescriAgent(idticket, data, autor) {
  const body = { descri: data }
  const uri = modifierDescriAgent + `${idticket}/${autor}`
  return putCall(uri, body, true)
}
export function getTicketbyID(idticket) {
  return getCall(getAllTicketURI + '/' + idticket, true)
}
export function assignation(data) {
  return postCall(assignationURL, data, true)
}
// function file
export function getFolderByPerson(idperson, path) {
  const route = getFolderURL + '/' + idperson
  const convertedPath = path.replace(/\\/g, '/') // Remplace tous les '\' par '/' dans le chemin
  const encodedPath = encodeURIComponent(convertedPath)
  const finalUrl = route + '?path=' + encodedPath
  return getCall(finalUrl, true)
}
export function getFilePersonGalery(idperson) {
  const uri = person_fileURI + '/' + idperson
  console.log(uri)
  return getCall(uri, true)
}
export function AddFolder(folder) {
  const convertedPath = folder.paths.replace(/\\/g, '/') // Remplace tous les '\' par '/' dans le chemin
  return postCall(addFolderURL + '?paths=' + encodeURIComponent(convertedPath), folder, true)
}
export function saveFile(file) {
  // Remplace tous les '\' par '/' dans le chemin
  file.path = file.path.replace(/\\/g, '/')
  return postCall(addFileURL, file, true, true)
}
export function saveCSV(file) {
  return postCall(importFileURL, file, true, true)
}
export function saveProfile(photo) {
  return postCall(saveProfileURI, photo, true)
}
export function getProfile(idperson) {
  return getCall(getProfileURI + '/' + idperson, true)
}
export function saveFileFolder(file) {
  return postCall(addFileTicketURI, file, true, true)
}
export function getFileTicket(idticket, action) {
  return getCall(getFileTicketURI + '/' + idticket + '?action=' + action, true)
}

// function maka soustache du ticket
export function getAllsubTask(idticket) {
  return getCall(getSousTicketURI + '/' + idticket, true)
}
// function maka soustache anle soustache
export function makaSubTask(idsoustache) {
  return getCall(getsubTask + '/' + idsoustache, true)
}
// function save subtask
export function saveSubtask(subtask) {
  return postCall(saveSousTicketURI, subtask, true)
}

//calendar
export function getAllevent(month, year, idresp) {
  return getCall(getCalendarByDate + '/' + month + '/' + year + '?idresp=' + idresp, true)
}
export function getAlleventByService(month, year, service, idresp) {
  return getCall(
    getCalendarByDate + '/' + month + '/' + year + '?idservice=' + service + '&idresp=' + idresp,
    true,
  )
}
export function getAllEventLate(action, idresp, isclient) {
  if (action === 2) return getCall(getCalendarByDateLate, true)
  else {
    return getCall(getCalendarByDateLate + '?idresp=' + idresp + '&isclient=' + isclient, true)
  }
}
export function getAllEventLateByService(action, idservice, idresp, isclient) {
  if (action === 2) return getCall(getCalendarByDateLate + '?idservice=' + idservice, true)
  else
    return getCall(
      getCalendarByDateLate +
        '?idservice=' +
        idservice +
        '&idresp=' +
        idresp +
        '&isclient=' +
        isclient,
      true,
    )
}
export function getAllEventLateByResp(idresp) {
  return getCall(getCalendarByDateLate + '?idresp=' + idresp, true)
}

export function getStatTotalFinish(month, year, action) {
  return getCall(statTotalfinish + month + '?year=' + year + '&action=' + action, true)
}
export function getChartTicket(year) {
  return getCall(chartTicketURI + year, true)
}
export function getStatFinishGroupByUser(month, year, action) {
  return getCall(statTotalfinishByUser + month + '?year=' + year + '&action=' + action, true)
}
export function getstatistiqueByService(month, year) {
  const uri = statistiqueByService + '?month=' + month + '&year=' + year
  return getCall(uri, true)
}
export function getstatistiqueBycatego(year, idservice) {
  const uri = statistiqueByCatego + '?year=' + year + '&idservice=' + idservice
  return getCall(uri, true)
}
export function getStatBystatus(month, year) {
  return getCall(statStatusBymonth + month + '?year=' + year, true)
}
export function getStatByPerformance(month, year) {
  return getCall(statPerformanceBymonth + month + '?year=' + year, true)
}
export function getStatPerformanceByUser(month, year, idresp) {
  return getCall(statPerformanceBymonth + month + '?year=' + year + '&idresp=' + idresp, true)
}

export function getStatByPerson(year, idservice, idresp, person = 'agent') {
  const uri = `${host}/ticket/stat/${person}?year=${year}&idresp=${idresp}&idservice=${idservice}`
  return getCall(uri, true)
}

export function filterTicket(filter) {
  return postCall(filterTicketURI, filter, true)
}
export function filterTicketPaginate(filter, page, size = maxSizePage) {
  let uri = `${filterTicketURI}/${page}/${size}`
  return postCall(uri, filter, true)
}
export function FactureByParam(param) {
  return getCall(hostFacture + '/' + param, true)
}
export function countAll(id) {
  return getCall(count + '?id=' + id, true)
}
export function ListTicketToFacture(param, date, idclient, row, maxsize = maxSizePage) {
  const uri =
    hostFacture +
    '/' +
    param +
    '/' +
    row +
    '/' +
    maxsize +
    '?date=' +
    date +
    '&idclient=' +
    idclient
  return getCall(uri, true)
}
export function saveFacture(facture) {
  return postCall(saveFactureURI, facture, true)
}
export function getFilterPerson(person, page, size = maxSizePage) {
  let uri = `${FilterPersonURI}${page}/${size}`
  return postCall(uri, person, true)
}
export function updateInfo(form) {
  return postCall(updateInfoPersonURI, form, true)
}
export function findAllFacture(page, profile, size = maxSizePage) {
  let uri = `${hostFacture}/allfacture/${page}/${size}`

  if (profile && Number(profile.role) === 0) uri += '?idclient=' + profile.id

  return getCall(uri, true)
}
export function findOneFacture(id) {
  return getCall(`${hostFacture}/one/${id}`, true)
}
