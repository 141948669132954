import swal from 'sweetalert'
import { checkTokenUser } from './Api'
import { Navigate } from 'react-router-dom'
import React from 'react'
export function getProfilStorage() {
  const user = localStorage.getItem('person_profil')
  let profile = null
  if (user) profile = JSON.parse(user)
  else return <Navigate to="/login" replace />
  return profile
}
/* eslint-disable no-unused-vars */
export const TokenUser = 'appUser-token'

// role sy action (type admin lay action na user na client)
// mandeha 0 1 2 client user admin
// eslint-disable-next-line react/prop-types
export function checkConnected(profile) {
  checkTokenUser(profile.id, profile.token)
    .then(() => {
      // window.location.reload()
    })
    .catch(() =>
      swal({
        title: 'Erreur: ',
        text: 'vous devez vous connecter!',
        icon: 'warning',
        dangerMode: true,
      }).then(() => {
        window.location.replace('/#/login')
      }),
    )
}
export const checkRole = (action, role) => {
  if (role < action) {
    // navigate('/login')
    alert("vous n'avez pas accès à cet action")
    localStorage.removeItem('person_profil')
    // eslint-disable-next-line react/react-in-jsx-scope
    return <Navigate to="/login" replace />
    // window.location.replace('/#/login')
  }
  return true
}
export const statusTicket = [
  { id: 0, name: 'en cours' },
  { id: 1, name: 'en attente' },
  { id: 2, name: 'terminé' },
  { id: 3, name: 'Demande' },
  { id: 6, name: 'Annulé' },
]
export const statusTicketCLI = [
  { id: 3, name: 'Demande' },
  { id: 6, name: 'Annulé' },
]
export const validateEmail = (str) => {
  const firstIndex = str.indexOf('@')
  const lastIndex = str.lastIndexOf('@')
  return firstIndex === lastIndex && firstIndex > 0 && lastIndex < str.length - 1
}
export const compare = (id, toCompare) => {
  let statusName = null
  let color = '#f9b115'
  toCompare.forEach((item) => {
    if (item.id === id) {
      statusName = item.name
      color = item.color
    }
  })
  return <span style={{ color: color }}>{statusName}</span>
}
