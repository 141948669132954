import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CCol, CFormInput, CRow } from '@coreui/react'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import 'src/scss/cs.css'
/* eslint-disable no-unused-vars */
const SearchableDatatable = ({
  data,
  columns,
  title,
  selectable,
  linkFunction,
  funcOnclick,
  handleRowSelection,
  contextActions,
  selectableRowDisabled,
  clearRow,
  // eslint-disable-next-line react/prop-types
  Action,
  Component,
  searchDB = false,
  funcDB,
}) => {
  const [filteredData, setFiltered] = useState()
  const filterBar = React.useMemo(() => {
    const filterData = (text) => {
      const words = text.split(' ')
      const filtered = data.filter((entry) => {
        if (text === '') return true
        for (const column of columns) {
          let target = []
          try {
            // console.log(column.selector(entry))
            if (column.selector(entry)) target = column.selector(entry).toString()
          } catch (error) {
            console.error(error)
          }
          if (words && target.length > 0) {
            // console.log(target)
            if (words?.some((word) => target?.match(new RegExp(word, 'i')) !== null)) {
              return true
            }
          }
        }
        return false
      })
      setFiltered(filtered)
    }

    return (
      <CCol sm={3} lg={2}>
        <CFormInput
          size="sm"
          type="text"
          placeholder="Rechercher..."
          onChange={(e) => filterData(e.target.value)}
        />
      </CCol>
    )
  }, [columns, data])
  const [text, setText] = useState('')
  useEffect(() => {
    if (funcDB) {
      funcDB(text)
        .then((result) => setFiltered(result))
        .catch((error) => console.log(error))
    }
  }, [text])
  const searchBar = React.useMemo(() => {
    return (
      <CCol sm={3} lg={2}>
        <CFormInput
          size="sm"
          type="text"
          placeholder="Rechercher..."
          onChange={(e) => setText(e.target.value)}
        />
      </CCol>
    )
  }, [data])
  const navigate = useNavigate()
  useEffect(() => {
    setFiltered(data)
  }, [data])
  return (
    <CRow>
      <DataTable
        title={title}
        columns={columns.map((column) => ({
          ...column,
          wrap: true, // Ajoutez cette ligne pour activer le retour à la ligne automatique
        }))}
        data={filteredData}
        selectableRows={selectable}
        // onSelectedRowsChange={handleChange}
        onSelectedRowsChange={handleRowSelection}
        selectableRowDisabled={
          selectableRowDisabled !== undefined ? selectableRowDisabled : () => false
        }
        // pagination
        subHeader
        pointerOnHover={true}
        contextActions={contextActions}
        subHeaderComponent={searchDB ? searchBar : filterBar}
        className={`clickable ${linkFunction ? 'custom-datatable-style' : ''}`}
        onRowClicked={(row) => {
          if (linkFunction) navigate(linkFunction(row))
          if (funcOnclick) funcOnclick(row)
        }}
        clearSelectedRows={clearRow}
        onRowDoubleClicked={Action}
      />
      {Component && (
        <>
          <p></p>
          <p></p>
          {Component}
        </>
      )}
    </CRow>
  )
}

SearchableDatatable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  selectable: PropTypes.bool,
  linkFunction: PropTypes.func,
  handleRowSelection: PropTypes.func,
  contextActions: PropTypes.object,
  selectableRowDisabled: PropTypes.func,
  clearRow: PropTypes.bool,
  Component: PropTypes.any,
  funcOnclick: PropTypes.func,
  searchDB: PropTypes.bool,
  funcDB: PropTypes.func,
}

export default SearchableDatatable
