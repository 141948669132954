import { useEffect, useState } from 'react'
import { CAlert, CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react'
import PropTypes from 'prop-types'
/* eslint-disable no-unused-vars */
export default function ShowAlert(props) {
  const [visible, setVisible] = useState(props.visible)
  const handleClose = () => {
    if (props.onCloseAlert) props.onCloseAlert(null)
    setVisible(false)
  }
  // const
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <CAlert color="danger" dismissible visible={visible} onClose={handleClose}>
        {props.message}
      </CAlert>
    </div>
  )
}
ShowAlert.propTypes = {
  visible: PropTypes.bool,
  onCloseAlert: PropTypes.func,
  message: PropTypes.string,
}
export function ShowToast({ message, setToast, toast }) {
  const [timeElapsed, setTimeElapsed] = useState(0)

  useEffect(() => {
    if (toast) {
      const timer = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1)
      }, 1000)

      setTimeout(() => {
        setToast(false)
        clearInterval(timer)
        setTimeElapsed(0)
      }, 3000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [toast])

  const handleToast = () => {
    setToast(false)
    setTimeElapsed(0)
  }

  const getTimeMessage = () => {
    if (timeElapsed <= 5) {
      return "à l'instant"
    } else {
      return `il y a ${timeElapsed} sec`
    }
  }

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      {/*eslint-disable-next-line react/react-in-jsx-scope*/}
      <CToaster placement={'middle-center'}>
        {/* eslint-disable-next-line react/react-in-jsx-scope */}
        <CToast color={'success'} autohide={false} visible={toast} onClose={handleToast}>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <CToastHeader closeButton>
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <div className="fw-bold me-auto">Sunsoft</div>
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <small>{getTimeMessage()}</small>
          </CToastHeader>
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <CToastBody>{message}</CToastBody>
        </CToast>
      </CToaster>
    </>
  )
}

ShowToast.propTypes = {
  message: PropTypes.string,
  setToast: PropTypes.func,
  toast: PropTypes.bool,
}
