import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { FormEmail, ResetPassword } from './views/pages/authentification/Recovery'
import { CSpinner } from '@coreui/react'

const loading = (
  // <div className="pt-3 text-center">
  <div
    className="p-3 d-flex justify-content-center align-items-center bg-transparent border-0"
    style={{ width: 'fit-content', height: 'fit-content' }}
  >
    {/*<div className="sk-spinner sk-spinner-pulse"></div>*/}
    <strong role="status">chargement...</strong>
    <CSpinner className="ms-auto" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/authentification/Login'))
const Page404 = React.lazy(() => import('./views/pages/page_error/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page_error/Page500'))
const Recovery = React.lazy(() => import('./views/pages/authentification/Recovery'))
class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            {/*<Route exact path="/" name="Login Page" element={<Login />} />*/}
            <Route exact path="/login" name="Login_Page" element={<Login />} />
            <Route exact path="/recovery" name="recovery" element={<FormEmail />} />
            <Route exact path="/confirm_code" name="confirm_code" element={<Recovery />} />
            <Route exact path="/reset" name="reset" element={<ResetPassword />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
